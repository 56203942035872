import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import {
  type InitialConfigType,
  type InitialEditorStateType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { type EditorState, ParagraphNode } from "lexical";
import React from "react";

import { cn } from "~/lib/utils";

import { OnTextContentChangePlugin } from "../../plugins/on-text-content-change";
import editorTheme from "../../themes/editor-theme";
import "./editor.css";
import { SpecialCharsToolbarPlugin } from "./special-chars-toolbar";
import { ToolbarPlugin } from "./toolbar";

function Placeholder() {
  return <div></div>;
}

interface Props {
  className?: string;
  onChange: (editorState: EditorState) => void;
  initialEditorState?: InitialEditorStateType;
  specialChars?: string;
  onContentChange?: (text: string) => void;
}

export const RichTextEditor = ({
  className,
  initialEditorState,
  onChange,
  onContentChange,
  specialChars,
}: Props): JSX.Element => {
  const editorConfig: InitialConfigType = {
    namespace: "MyEditor",
    theme: editorTheme,
    onError(error: Error) {
      throw error;
    },
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      AutoLinkNode,
      LinkNode,
      ParagraphNode,
    ],
    editorState: initialEditorState,
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <OnChangePlugin onChange={onChange} />
      <OnTextContentChangePlugin onChange={onContentChange} />
      <div className="bg-background w-full overflow-hidden rounded-xl border shadow-md">
        <ToolbarPlugin />
        <SpecialCharsToolbarPlugin specialChars={specialChars} />
        <div>
          <RichTextPlugin
            contentEditable={
              <div>
                <ContentEditable
                  className={cn(
                    "placeholder:text-muted-foreground min-h-[260px] w-full whitespace-pre-line bg-transparent px-3 py-2 text-sm shadow-sm focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50",
                    "prose-sm dark:prose-invert prose-a:text-blue-500 prose-a:underline prose-a:underline-offset-2",
                    className,
                  )}
                />
              </div>
            }
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          {/* <AutoFocusPlugin /> */}
          <ListPlugin />
          <LinkPlugin />
        </div>
      </div>
    </LexicalComposer>
  );
};
