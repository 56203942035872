import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";

export function OnTextContentChangePlugin({
  onChange,
}: {
  onChange?: (text: string) => void;
}) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!onChange) {
      return;
    }

    return editor.registerTextContentListener((textContent) => {
      onChange(textContent);
    });
  }, [editor, onChange]);

  return null;
}
